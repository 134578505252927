
import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderStyles from 'react-awesome-slider/src/styled/open-animation';
import { graphql } from 'gatsby';
//import { trackCustomEvent } from 'gatsby-plugin-google-analytics';


import BlockContent from 'molecules/BlockContent';
import Container from 'molecules/Container';
import FullScreenIcon from 'static/images/full-screen.inline.svg';
import Link from 'molecules/Link';
//import LoadWhenVisible from 'molecules/LoadWhenVisible';
import SEO from 'molecules/SEO';
//import ShareButtons from 'molecules/ShareButtons';
//import WithBGImage from 'molecules/WithBGImage';

import { presentationMetaFields } from 'lib/fragments';

import 'react-awesome-slider/dist/styles.css';

import './SinglePresentation.scss';


/**
 * Single Presentation
 */
export const query = graphql`
  query SinglePresentation( $id: String ) {
    sanityPresentation( id: { eq: $id } ){
      _createdAt( formatString: "MMMM Do, YYYY" )
      images {
        altText
        image {
          asset {
            url
            metadata {
              lqip
            }
          }
        }
      }
      title
      link {
        url
      }
      ...presentationMetaFields
    }
  }
`;
export default function SingleResearch( { data, path } ) {

  const presentation = React.useRef( null );

  const {
    _createdAt,
    images,
    title,
    link,
    meta
  } = data.sanityPresentation;

  let media = [];

  if( !! images.length ) {
    for( let { altText, image } of images ) {
      media.push( {
        source: image.asset.url
       } );
    }
  }

  /**
   * Navigation.
   */
  const [ current, setCurrent ] = React.useState( 0 );
  const [ isTransitioning, setIsTransitioning ] = React.useState( false );

  /**
   * Handle transition.
   */
  const handleTransition = () => setIsTransitioning( true );

  /**
   * Handle change.
   */
  const handleChange = ( { currentIndex } ) => {
    setCurrent( currentIndex );
    setIsTransitioning( false );
  }

  /**
   * Handle key press.
   */
  const handleKeyPress = ( { keyCode, repeat } ) => {

    
    const currentSlide = parseInt( presentation.current.dataset.current );
    const transitioning = presentation.current.dataset.transitioning;

    if( transitioning === 'true' ) {
      return;
    }

    if( keyCode === 37 ) {
      setCurrent( currentSlide - 1 );
    }
    else if( keyCode == 39 ) {
      setCurrent( currentSlide + 1 );
    }
  }

  /**
   *  Handle key navigation.
   */
  React.useEffect( () => {

    window.addEventListener( 'keydown', handleKeyPress );

    return () => {
      window.removeEventListener( 'keydown', handleKeyPress );
    }

  }, [] );


  /**
   * Toggle fullscreen.
   */
  const toggleFullScreen = () => {

    const el = presentation.current;
    const fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;

    if( ! fullscreenElement ) {
      if( !! el.webkitRequestFullscreen ) {
        el.webkitRequestFullscreen( Element.ALLOW_KEYBOARD_INPUT );
      }
      else if( !! el.mozRequestFullScreen ) {
        el.mozRequestFullScreen()
      }
      else if( !! el.msRequestFullscreen ) {
        el.msRequestFullscreen()
      }
      else if( !! el.requestFullscreen ) {
        el.requestFullscreen();
      }
    }
    else {
      if( document.exitFullscreen ) {
        document.exitFullscreen();
      } 
      else if( document.mozCancelFullScreen ) {
        document.mozCancelFullScreen();
      } 
      else if( document.webkitExitFullscreen ) {
        document.webkitExitFullscreen();
      }
    }

  }

  
  return(

    <div 
    ref={ presentation }
    className="Presentation"
    data-current={ current }
    data-transitioning={ isTransitioning }
    >
     
      <AwesomeSlider
      animation={ 'openAnimation' }
      selected={ current }
      onTransitionStart={ handleTransition }
      onTransitionEnd={ handleChange }
      >
        { !! media.length && media.map( ( item, key ) => {

          const lastItem = ( key + 1 ) >= media.length;

          return (
            <div
            className={ `Presentation__slide ${lastItem && !! link && 'is-link'}` }
            key={ key }
            data-src={ item.source } 
            >
              { !! lastItem && !! link && 
                <a href={ link.url }>
                </a>
              }
            </div>
          )
        } ) }
      </AwesomeSlider>

      <div class="Presentation__toolbar">
        <button
        className="Presentation__fullscreen"
        onClick={ toggleFullScreen }
        >
          <FullScreenIcon  />
        </button>

      </div>

    
    </div>
      

  )

 

};